<template>
  <div class="skv-questions">
    <div class="skv-questions__tabs">
      <div
        class="skv-questions__tab"
        @click="changeTab(0)"
        :class="{ 'skv-questions__tab_active': activeTab === 0 }"
      >
        Индекс активности СКВ по шкале SLEDAI-2K
      </div>
      <div
        class="skv-questions__tab"
        @click="changeTab(1)"
        :class="{ 'skv-questions__tab_active': activeTab === 1 }"
      >
        Индекс повреждения при СКВ SLICC/ACR Damage index
      </div>
    </div>
    <div
      class="skv-questions__content"
      v-for="(tab, tabInd) in tabs"
      :key="tabInd"
      v-show="activeTab === tabInd"
    >
      <div class="skv-questions__content-head">
        <div class="skv-questions__content-title" v-html="tab.title"></div>
        <div
          class="skv-questions__content-description"
          v-html="tab.description"
        ></div>
      </div>
      <div class="skv-questions__content-steps" ref="steps">
        <div
          class="skv-questions__content-step"
          :class="{
            'skv-questions__content-step_active': tab.activeStep === ind,
            'skv-questions__content-step_done': tab.activeStep > ind,
          }"
          v-for="(step, ind) in tab.steps"
          :key="ind"
        >
          <div class="skv-questions__content-step-number">
            <svg
              v-if="tab.activeStep > ind"
              width="11"
              height="9"
              viewBox="0 0 11 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 5.2L3.57143 8L10 1"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span v-else>{{ ind + 1 }}</span>
          </div>
        </div>
        <div
          class="skv-questions__content-step skv-questions__content-step_result"
          :class="{
            'skv-questions__content-step_active':
              tab.activeStep === tab.steps.length,
            'skv-questions__content-step_done':
              tab.activeStep > tab.steps.length,
          }"
        >
          <div class="skv-questions__content-step-number">
            <svg
              v-if="tab.activeStep > tab.steps.length"
              width="11"
              height="9"
              viewBox="0 0 11 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 5.2L3.57143 8L10 1"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="skv-questions__content-step-tip">Результат</div>
        </div>
      </div>
      <div class="skv-questions__content-steps-body">
        <div
          class="skv-questions__content-step-body"
          v-show="ind === tab.activeStep"
          v-for="(body, ind) in tab.steps"
          :key="ind"
        >
          <div class="skv-questions__step-title" v-html="body.title"></div>
          <div
            class="skv-questions__input-container-wrap"
            v-for="(input, j) in body.items"
            :key="j"
          >
            <div
              class="skv-questions__input-container"
              v-if="!input.group_title"
            >
              <div
                class="skv-questions__checkbox-wrap"
                :class="{
                  'skv-questions__checkbox-wrap_active':
                    tab.steps[ind].items[j].value,
                }"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 6L6.5 11L4 8.72727"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <input
                  :id="`checkbox_${tabInd}_${ind}_${j}`"
                  type="checkbox"
                  v-model="tab.steps[ind].items[j].value"
                  :value="input.cost"
                />
              </div>
              <label
                :for="`checkbox_${tabInd}_${ind}_${j}`"
                class="skv-questions__checkbox-text"
              >
                <div
                  class="skv-questions__checkbox-title"
                  v-if="input.title"
                  v-html="input.title"
                ></div>
                <div
                  class="skv-questions__checkbox-description"
                  v-html="input.description"
                ></div>
              </label>
            </div>
            <div v-else>
              <div
                class="skv-questions__step-group-title"
                v-html="input.group_title"
              ></div>
              <div
                class="skv-questions__input-container"
                v-for="(radio, k) in input.group_items"
                :key="k"
              >
                <div
                  class="skv-questions__radio-wrap"
                  :class="{
                    'skv-questions__radio-wrap_active':
                      tab.steps[ind].items[j].value === radio.value,
                  }"
                >
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="10" height="10" rx="5" fill="#D0006F" />
                  </svg>
                  <input
                    :id="`radio_${tabInd}_${ind}_${j}_${k}`"
                    type="radio"
                    v-model="tab.steps[ind].items[j].value"
                    :value="radio.value"
                  />
                </div>
                <label
                  :for="`radio_${tabInd}_${ind}_${j}_${k}`"
                  class="skv-questions__checkbox-text"
                >
                  <div
                    class="skv-questions__checkbox-title"
                    v-if="radio.title"
                    v-html="radio.title"
                  ></div>
                  <div
                    class="skv-questions__checkbox-description"
                    v-html="radio.description"
                  ></div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          class="skv-questions__content-steps-body-result"
          v-show="tab.activeStep >= tab.steps.length"
        >
          <div
            class="skv-questions__content-steps-body-result-block"
            v-for="(block, i) in tab.steps"
            :key="i"
          >
            <div class="result-block__title" v-html="block.title"></div>
            <div class="result-block__items">
              <div
                class="result-block__item"
                v-for="(item, j) in block.items"
                :key="j"
              >
                <div
                  class="result-block__item-title"
                  v-html="item.title || item.description"
                  v-if="!item.group_title"
                ></div>
                <div class="result-block__item-group" v-else>
                  <div
                    class="result-block__item-title"
                    v-html="item.title || item.group_title"
                  ></div>
                  <div
                    class="result-block__item"
                    v-for="(group_item, k) in item.group_items"
                    :key="k"
                  >
                    <div
                      class="result-block__item-title"
                      v-html="group_item.title || group_item.description"
                    ></div>
                    <div class="result-block__item-state">
                      <svg
                        v-if="item.value === group_item.value"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.5198 9.54062C16.8184 9.25353 16.8277 8.77875 16.5406 8.48017C16.2535 8.18159 15.7787 8.17228 15.4802 8.45938L10.8157 12.9445L8.53566 10.6179C8.24574 10.3221 7.77089 10.3173 7.47505 10.6072C7.17922 10.8971 7.17442 11.372 7.46434 11.6678L10.2643 14.5249C10.5523 14.8188 11.0233 14.8258 11.3198 14.5406L16.5198 9.54062Z"
                          fill="#3C4242"
                        />
                      </svg>
                      <svg
                        v-else
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.5 12H4.5"
                          stroke="#3C4242"
                          stroke-width="1.5"
                          stroke-linecap="square"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="result-block__item-state" v-if="!item.group_title">
                  <svg
                    v-if="item.value"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.5198 9.54062C16.8184 9.25353 16.8277 8.77875 16.5406 8.48017C16.2535 8.18159 15.7787 8.17228 15.4802 8.45938L10.8157 12.9445L8.53566 10.6179C8.24574 10.3221 7.77089 10.3173 7.47505 10.6072C7.17922 10.8971 7.17442 11.372 7.46434 11.6678L10.2643 14.5249C10.5523 14.8188 11.0233 14.8258 11.3198 14.5406L16.5198 9.54062Z"
                      fill="#3C4242"
                    />
                  </svg>
                  <svg
                    v-else
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.5 12H4.5"
                      stroke="#3C4242"
                      stroke-width="1.5"
                      stroke-linecap="square"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div class="skv-questions__result">
            Результат:
            <span
              >{{ amount[tabInd] || 0 }}
              {{
                getNoun(amount[tabInd] || 0, "балл", "балла", "баллов")
              }}</span
            >
          </div>
          <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :filename="tab.title"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            :ref="`html2Pdf_${tabInd}`"
            @hasDownloaded="onFinish($event)"
          >
            <section slot="pdf-content">
              <div class="pdf-block__content">
                <div class="pdf-block__caption" v-html="tab.title"></div>
                <div class="pdf-block" v-for="(block, i) in tab.steps" :key="i">
                  <div class="html2pdf__page-break" v-if="block.break"></div>
                  <div class="pdf-block__title" v-html="block.title"></div>
                  <div class="pdf-block__items">
                    <div
                      class="pdf-block__item"
                      v-for="(item, j) in block.items"
                      :key="j"
                    >
                      <div
                        class="pdf-block__item-title"
                        v-if="!item.group_title"
                      >
                        <span v-html="item.title || item.description"></span>
                      </div>
                      <div class="pdf-block__item-group" v-else>
                        <div
                          class="pdf-block__item-title"
                          :class="{
                            'pdf-block__item-group-title': item.group_title,
                          }"
                          v-html="item.title || item.group_title"
                        ></div>
                        <div
                          class="pdf-block__item"
                          v-for="(group_item, k) in item.group_items"
                          :key="k"
                        >
                          <div class="pdf-block__item-title">
                            <span
                              v-html="
                                group_item.title || group_item.description
                              "
                            ></span>
                          </div>
                          <div class="pdf-block__item-state">
                            <svg
                              v-if="item.value === group_item.value"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.5198 9.54062C16.8184 9.25353 16.8277 8.77875 16.5406 8.48017C16.2535 8.18159 15.7787 8.17228 15.4802 8.45938L10.8157 12.9445L8.53566 10.6179C8.24574 10.3221 7.77089 10.3173 7.47505 10.6072C7.17922 10.8971 7.17442 11.372 7.46434 11.6678L10.2643 14.5249C10.5523 14.8188 11.0233 14.8258 11.3198 14.5406L16.5198 9.54062Z"
                                fill="#3C4242"
                              />
                            </svg>
                            <svg
                              v-else
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.5 12H4.5"
                                stroke="#3C4242"
                                stroke-width="1.5"
                                stroke-linecap="square"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div
                        class="pdf-block__item-state"
                        v-if="!item.group_title"
                      >
                        <svg
                          v-if="item.value"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.5198 9.54062C16.8184 9.25353 16.8277 8.77875 16.5406 8.48017C16.2535 8.18159 15.7787 8.17228 15.4802 8.45938L10.8157 12.9445L8.53566 10.6179C8.24574 10.3221 7.77089 10.3173 7.47505 10.6072C7.17922 10.8971 7.17442 11.372 7.46434 11.6678L10.2643 14.5249C10.5523 14.8188 11.0233 14.8258 11.3198 14.5406L16.5198 9.54062Z"
                            fill="#3C4242"
                          />
                        </svg>
                        <svg
                          v-else
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.5 12H4.5"
                            stroke="#3C4242"
                            stroke-width="1.5"
                            stroke-linecap="square"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="pdf-block__result">
                  Результат:
                  <span
                    >{{ amount[tabInd] || 0 }}
                    {{
                      getNoun(amount[tabInd] || 0, "балл", "балла", "баллов")
                    }}</span
                  >
                </div>
              </div>
            </section>
          </vue-html2pdf>
        </div>
        <div class="skv-questions__content-steps-buttons">
          <div
            class="skv-questions__content-steps-back button"
            @click="prev(tabInd)"
            v-if="tab.activeStep > 0"
          >
            <svg
              class="mr-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 4.5L7.5 12L15 19.5"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="square"
              />
            </svg>
            Назад
          </div>
          <div
            class="skv-questions__content-steps-next button"
            @click="next(tabInd, tab.steps.length)"
            v-if="tab.activeStep < tab.steps.length"
          >
            Далее
            <svg
              class="ml-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 4.5L16.5 12L9 19.5"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="square"
              />
            </svg>
          </div>
          <div
            class="skv-questions__content-steps-next button skv-questions__download-pdf"
            :class="{ 'skv-questions__download-pdf_progress': progress }"
            v-if="tab.activeStep >= tab.steps.length"
            @click="generateReport"
          >
            <div class="button__filler" :style="`width: ${progress}%`">
              <span>Скачать PDF</span>
            </div>
            <span>Скачать PDF</span>
          </div>
          <div
            class="skv-questions__content-steps-back button"
            @click="$emit('repeat', activeTab)"
            v-if="tab.activeStep >= tab.steps.length"
          >
            Пройти заново
          </div>
        </div>
      </div>
    </div>
    <div class="skv-questions__bottom">
      <div class="skv-questions__bottom-row">
        <div class="skv-questions__bottom-left">
          <div class="skv-questions__bottom-title">
            Индекс по шкале SLEDAI-2K
          </div>
          <div class="skv-questions__bottom-description">
            Выделяют 4 степени активности СКВ:
            <br />
            <ul class="mt-4" style="list-style: disc; padding-left: 24px">
              <li>нет активности / ремиссия (0 баллов);</li>
              <li>низкая активность (1-4 балла);</li>
              <li>средняя степень активности (5-10 баллов);</li>
              <li>высокая степень активности (более 10 баллов).</li>
            </ul>
          </div>
        </div>
        <div class="skv-questions__bottom-right">
          <div class="skv-questions__bottom-title">
            Индекс по шкале СКВ SLICC
          </div>
          <div class="skv-questions__bottom-description">
            Выделяют 4 степени индекса повреждений:
            <br />
            <ul class="mt-4"  style="list-style: disc; padding-left: 24px">
              <li>отсутствие повреждений (0 баллов);</li>
              <li>низкий ИП (1 балл);</li>
              <li>средний ИП (2-4 балла);</li>
              <li>высокий ИП (более 4 баллов).</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="skv-questions__bottom-title mt-6">Список сокращений</div>
      <div class="skv-questions__bottom-description">
        СКВ — Системная красная волчанка.<br /><br />
        ЭМГ – электромиография.<br /><br />
        ЖКТ – желудочно-кишечный тракт.<br /><br />
        SLEDAI 2K (The Systemic Lupus Erythematosus Disease Activity Index 2000)
        — Индекс оценки общей активности системной красной волчанки.
        <br /><br />
        SLICC/ACR Damage Index (The Systemic Lupus Erythematosus International
        Collaborating Clinics Group/ American College of Rheumathology Damage
        Index) – индекс оценки необратимых органных повреждений при СКВ
        Международной группы сотрудничества клиник системной красной волчанки и
        Американской коллегии ревматологов.
      </div>
      <div class="skv-questions__bottom-title mt-6">Источники</div>
      <ol>
        <li>
          Министерство здравоохранения Российской Федерации, Ассоциация
          Ревматологов России, Клинические рекомендации, Системная красная
          волчанка 2016. Электронный ресурс. Дата доступа: 09.06.2023.
          <a
            href="https://rheumatolog.ru/experts/klinicheskie-rekomendacii/"
            target="_blank"
            >https://rheumatolog.ru/experts/klinicheskie-rekomendacii/</a
          >
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
export default {
  name: "SKVQuestions",
  props: {
    savedActiveTab: { type: Number, default: null },
  },
  components: {
    VueHtml2pdf,
  },
  data: () => ({
    activeTab: 0,
    progress: 0,
    tabs: [
      {
        title: "Индекс активности СКВ по шкале SLEDAI-2K",
        description:
          " Отметить все проявления, имевшие место на момент осмотра или в течение 10 предшествующих осмотру дней.",
        activeStep: 0,
        steps: [
          {
            title: "Проявления, оцениваемые в 8 баллов:",
            items: [
              {
                title: "Эпилептический приступ",
                description:
                  "Недавно возникший (последние 10 дней), исключая метаболические, инфекционные и лекарственные причины",
                cost: 8,
                value: false,
              },
              {
                title: "Психоз",
                description:
                  "Нарушение способности выполнять нормальные действия в нормальном режиме вследствие выраженного изменения восприятия действительности, включая галлюцинации, бессвязность, значительное снижение ассоциативных способностей, истощение мыслительной деятельности, выраженное алогичное мышление; странное, дезорганизованное или кататоническое поведение. Исключить подобные состояния, вызванные уремией или лекарственными препаратами",
                cost: 8,
                value: false,
              },
              {
                title: "Органические мозговые синдромы",
                description:
                  "Изменения в глазу или на сетчатке, включая клеточные тельца, кровоизлияния, серозный экссудат или геморрагии в сосудистой оболочке, или неврит зрительного нерва, склерит, эписклерит. Исключить случаи подобных изменений при гипертензии, инфекции и лекарственных воздействиях",
                cost: 8,
                value: false,
              },
              {
                title: "Зрительные нарушения",
                description:
                  "Нарушение умственной деятельности с нарушением ориентации, памяти или других интеллектуальных способностей с острым началом и нестойкими клиническими проявлениями, включая затуманенность сознания со сниженной способностью к концентрации и неспособностью сохранять внимание к окружающему, плюс минимум 2 из следующих: нарушение восприятия, бессвязная речь, бессонница или сонливость в дневное время, снижение или повышение психомоторной активности. Исключить метаболические, инфекционные и лекарственные воздействия",
                cost: 8,
                value: false,
              },
              {
                title: "Расстройства со стороны черепно-мозговых нервов",
                description:
                  "Впервые возникшая чувствительная или двигательная невропатия черепно-мозговых нервов, включая головокружение, развившееся вследствие волчанки",
                cost: 8,
                value: false,
              },
              {
                title: "Головная боль",
                description:
                  "Выраженная персистирующая головная боль (может быть мигренозной), не отвечающая на наркотические анальгетики",
                cost: 8,
                value: false,
              },
              {
                title: "Нарушение мозгового кровообращения",
                description:
                  "Впервые возникшее, исключая таковое вследствие атеросклероза или гипертензии",
                cost: 8,
                value: false,
              },
              {
                title: "Васкулит",
                description:
                  "Язвы, гангрена, болезненные узелки на пальцах, околоногтевые инфаркты и геморрагии или данные биопсии или ангиограммы, подтверждающие васкулит",
                cost: 8,
                value: false,
              },
            ],
          },
          {
            title: "Проявления, оцениваемые в 4 балла:",
            items: [
              {
                title: "Артрит",
                description:
                  "Более двух болезненных суставов с признаками воспаления (болезненность, отек или выпот)",
                cost: 4,
                value: false,
              },
              {
                title: "Миозит",
                description:
                  "Проксимальная мышечная боль/слабость, ассоциированная с повышенным уровнем креатинфосфокиназы/альдолазы, или данные ЭМГ или биопсии, подтверждающие миозит",
                cost: 4,
                value: false,
              },
              {
                title: "Цилиндрурия",
                description: "Зернистые или эритроцитарные цилиндры",
                cost: 4,
                value: false,
              },
              {
                title: "Гематурия",
                description:
                  ">5 эритроцитов в поле зрения, исключая камни, инфекционные и другие причины",
                cost: 4,
                value: false,
              },
              {
                title: "Протеинурия",
                description:
                  "Острое начало или недавнее появление в количестве >0,5 грамм в сутки",
                cost: 4,
                value: false,
              },
              {
                title: "Пиурия",
                description:
                  ">5 лейкоцитов в поле зрения, исключая инфекционные причины",
                cost: 4,
                value: false,
              },
            ],
          },
          {
            title: "Проявления, оцениваемые в 2 балла:",
            items: [
              {
                title: "Высыпания",
                description:
                  "Новые или продолжающиеся высыпания на коже воспалительного характера",
                cost: 2,
                value: false,
              },
              {
                title: "Алопеция",
                description:
                  "Впервые возникшее или продолжающееся повышенное очаговое или диффузное выпадение волос вследствие активности волчанки",
                cost: 2,
                value: false,
              },
              {
                title: "Язвы слизистых оболочек",
                description:
                  "Впервые возникшее или продолжающееся изъязвление слизистых оболочек рта и носа вследствие активности волчанки",
                cost: 2,
                value: false,
              },
              {
                title: "Плеврит",
                description:
                  "Боль в грудной клетки с шумом трения плевры или выпотом, или утолщение плевры вследствие волчанки",
                cost: 2,
                value: false,
              },
              {
                title: "Перикардит",
                description:
                  "Перикардиальная боль с одним из следующих признаков: шум трения перикарда, выпот, электрокардиографическое подтверждение",
                cost: 2,
                value: false,
              },
              {
                title: "Низкое содержание компонентов комплимента",
                description:
                  "Снижение СН50, С3 или С4 ниже границы нормы тестирующей лаборатории",
                cost: 2,
                value: false,
              },
              {
                title: "Повышенное количество антител к двуспиральной ДНК",
                description:
                  ">25% связывания по методу Фарра или превышение нормальных значений тестирующей лаборатории",
                cost: 2,
                value: false,
              },
            ],
          },
          {
            title: "Проявления, оцениваемые в 1 балл:",
            items: [
              {
                title: "Лихорадка",
                description: ">38°С, исключить инфекционные причины",
                cost: 1,
                value: false,
              },
              {
                title: "Тромбоцитопения",
                description:
                  "<100 000 клеток /мм<sup style='color: inherit'>3</sup>",
                cost: 1,
                value: false,
              },
              {
                title: "Лейкопения",
                description:
                  "<3000 клеток /м<sup style='color: inherit'>3</sup> исключая лекарственные причины",
                cost: 1,
                value: false,
              },
            ],
          },
        ],
      },
      {
        activeStep: 0,
        title: "Индекс повреждения при СКВ SLICC/ACR Damage index",
        description:
          "В балльную оценку включаются все типы повреждения с момента установленного диагноза СКВ (обусловленного непосредственно СКВ или развившиеся вследствие проводимой терапии), при этом учитываются только признаки, сохраняющиеся в течение 6 мес. и более.",
        steps: [
          {
            title: "Орган зрения (каждый глаз) при клинической оценке",
            items: [
              {
                description: "Любая катаракта",
                value: false,
                cost: 1,
              },
              {
                description: "Изменения сетчатки или атрофия зрительного нерва",
                value: false,
                cost: 1,
              },
            ],
          },
          {
            title: "Нервная система",
            items: [
              {
                description:
                  "Когнитивные нарушения (снижение памяти, трудности со счетом, плохая концентрация, трудности в разговорной речи или письме, нарушенный уровень исполнения) или большие психозы",
                value: false,
                cost: 1,
              },
              {
                description:
                  "Судорожные припадки, требующие лечения в течение более 6 мес.",
                value: false,
                cost: 1,
              },
              {
                description:
                  "Черепно-мозговая или периферическая нейропатия (исключая зрительную)",
                value: false,
                cost: 1,
              },
              {
                description: "Поперечный миелит",
                value: false,
                cost: 1,
              },
              {
                group_title: "Инсульты когда-либо:",
                value: false,
                cost: 1,
                group_items: [
                  {
                    description: "один раз",
                    value: "один раз",
                  },
                  {
                    description: "более одного раза",
                    value: "более одного раза",
                  },
                ],
              },
            ],
          },
          {
            title: "Почки",
            items: [
              {
                description: "Скорость клубочковой фильтрации <50 мл/мин",
                value: false,
                cost: 1,
              },
              {
                description: "Протеинурия >3,5 г/24 часа",
                value: false,
                cost: 1,
              },
              {
                description:
                  "Конечная стадия почечного заболевания (независимо от диализа или трансплантации)",
                value: false,
                cost: 1,
              },
            ],
          },
          {
            title: "Легкие",
            items: [
              {
                description:
                  "Легочная гипертензия (выбухание правого желудочка или звонкий II тон)",
                value: false,
                cost: 1,
              },
              {
                description: "Легочный фиброз (физикально и рентгенологически)",
                value: false,
                cost: 1,
              },
              {
                description: "Сморщенное легкое (рентгенологически)",
                value: false,
                cost: 1,
              },
              {
                description: "Плевральный фиброз (рентгенологически)",
                value: false,
                cost: 1,
              },
              {
                description: "Инфаркт легкого (рентгенологически)",
                value: false,
                cost: 1,
              },
            ],
          },
          {
            title: "Сердечно-сосудистая система",
            items: [
              {
                description: "Стенокардия или аорто-коронарное шунтирование",
                cost: 1,
                value: false,
              },
              {
                description: "Кардиомиопатия (дисфункция желудочков)",
                cost: 1,
                value: false,
              },
              {
                description:
                  "Поражение клапанов (диастолический или систолический шум >3/6)",
                cost: 1,
                value: false,
              },
              {
                description:
                  "Перикардит в течение 6 месяцев (или перикардэктомия)",
                cost: 1,
                value: false,
              },
              {
                group_title: "Инфаркт миокарда когда-либо:",
                cost: 1,
                value: false,
                group_items: [
                  {
                    description: "один раз",
                    value: "один раз",
                  },
                  {
                    description: "более одного раза",
                    value: "более одного раза",
                  },
                ],
              },
            ],
          },
          {
            title: "Периферические сосуды",
            items: [
              {
                description: "Перемежающаяся хромота в течение 6 месяцев",
                cost: 1,
                value: false,
              },
              {
                description: "Небольшая потеря ткани (подушечка пальца)",
                cost: 1,
                value: false,
              },
              {
                description:
                  "Венозный тромбоз с отёком, изъязвлением или венозным стазом",
                cost: 1,
                value: false,
              },
              {
                group_title:
                  "Значительная потеря ткани когда-либо (потеря пальца или конечности):",
                value: false,
                cost: 1,
                group_items: [
                  {
                    description: "в одном месте",
                    value: "в одном месте",
                  },
                  {
                    description: "более чем в одном месте",
                    value: "более чем в одном месте",
                  },
                ],
              },
            ],
          },
          {
            title: "Желудочно-кишечный тракт",
            break: true,
            items: [
              {
                description: "Мезентериальная недостаточность",
                cost: 1,
                value: false,
              },
              {
                description: "Хронический перитонит",
                cost: 1,
                value: false,
              },
              {
                description:
                  "Стриктуры или хирургические операции на верхней части ЖКТ",
                cost: 1,
                value: false,
              },
              {
                group_title:
                  "Инфаркт, резекция кишечника (ниже 12-перстной кишки), селезёнки, печени или желчного пузыря когда-либо по любым причинам",
                value: false,
                cost: 1,
                group_items: [
                  {
                    description: "в одном месте",
                    value: "в одном месте",
                  },
                  {
                    description: "более чем в одном месте",
                    value: "более чем в одном месте",
                  },
                ],
              },
            ],
          },
          {
            title: "Костно-мышечная система",
            items: [
              {
                description: "Мышечная атрофия или слабость",
                value: false,
                cost: 1,
              },
              {
                description:
                  "Деформирующий или эрозивный артрит (включая вправимые деформации, исключая аваскулярные некрозы)",
                value: false,
                cost: 1,
              },
              {
                description:
                  "Остеопороз с переломами или коллапсом позвонков (исключая аваскулярный некроз)",
                value: false,
                cost: 1,
              },
              {
                description: "Остеомиелит",
                value: false,
                cost: 1,
              },
              {
                group_title: "Аваскулярный некроз",
                cost: 1,
                value: false,
                group_items: [
                  {
                    description: "одно событие",
                    value: "одно событие",
                  },
                  {
                    description: "более одного события",
                    value: "более одного события",
                  },
                ],
              },
            ],
          },
          {
            title: "Кожа",
            items: [
              {
                description: "Рубцовая хроническая алопеция",
                value: false,
                cost: 1,
              },
              {
                description:
                  "Обширное рубцевание или панникулит (кроме волосистой части и подушечек пальцев)",
                value: false,
                cost: 1,
              },
              {
                description:
                  "Изъязвление кожи (исключая тромбоз) в течение 6 месяцев",
                value: false,
                cost: 1,
              },
              {
                description: "Поражение половой системы",
                value: false,
                cost: 1,
              },
              {
                description: "Сахарный диабет (вне зависимости от лечения) ",
                value: false,
                cost: 1,
              },
              {
                group_title: "Малигнизация (исключая дисплазии)",
                value: false,
                cost: 1,
                group_items: [
                  {
                    description: "в одном месте",
                    value: "в одном месте",
                  },
                  {
                    description: "более чем в одном месте",
                    value: "более чем в одном месте",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }),
  computed: {
    amount() {
      let res = [];
      for (let tab in this.tabs) {
        for (let steps of this.tabs[tab].steps) {
          steps.items
            .filter((el) => el.value)
            .map((el) => {
              res[tab] = (res[tab] || 0) + el.cost;
            });
        }
      }
      return res;
    },
  },
  methods: {
    onFinish(e) {
      this.progress = 100;
      setTimeout(() => {
        this.progress = 0;
      }, 1000);
    },
    generateReport() {
      this.$set(
        this["tabs"][this.activeTab],
        "activeStep",
        this.tabs[this.activeTab].steps.length + 1
      );
      this.progress = 90;
      setTimeout(() => {
        this.$refs[`html2Pdf_${this.activeTab}`][0].generatePdf();
      }, 500);
    },
    getNoun(number, one, two, five) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    },
    next(tabInd, length) {
      if (this.tabs[tabInd].activeStep < length) {
        this.$set(
          this["tabs"][tabInd],
          "activeStep",
          this.tabs[tabInd].activeStep + 1
        );
        document.body.scrollIntoView({ behavior: "smooth" });
        this.scrollSteps();
      }
    },
    prev(tabInd) {
      if (this.tabs[tabInd].activeStep > 0) {
        if (this.tabs[tabInd].activeStep > this.tabs[tabInd].steps.length) {
          this.$set(
            this["tabs"][tabInd],
            "activeStep",
            this.tabs[tabInd].steps.length - 1
          );
        } else {
          this.$set(
            this["tabs"][tabInd],
            "activeStep",
            this.tabs[tabInd].activeStep - 1
          );
        }

        document.body.scrollIntoView({ behavior: "smooth" });
        this.scrollSteps();
      }
    },
    scrollSteps() {
      setTimeout(() => {
        if (
          this.$refs.steps[this.activeTab].querySelector(
            ".skv-questions__content-step_active"
          ).previousElementSibling
        ) {
          const position = Math.round(
            this.$refs.steps[this.activeTab].querySelector(
              ".skv-questions__content-step_active"
            ).previousElementSibling.offsetLeft - 32
          );
          // прокручиваем область просмотра до нужной позиции
          this.$refs.steps[this.activeTab].scrollTo({
            top: 0,
            left: position,
            // плавно
            behavior: "smooth",
          });
        }
      }, 200);
    },
    changeTab(tabInd) {
      this.activeTab = tabInd;
      const newUrl = window.location.pathname + `?tab=${tabInd}`;
      window.history.pushState(null, null, newUrl);
    },
  },
  mounted() {
    if (this.$route && this.$route.query && this.$route.query.tab) {
      let tabParam = this.$route.query.tab;
      if (!isNaN(tabParam)) {
        this.activeTab = parseInt(tabParam, 10);
      }
    }

    if (this.savedActiveTab) {
      this.activeTab = this.savedActiveTab;
      document.body.scrollIntoView({ behavior: "smooth" });
    }
  },
  created() {
    window.addEventListener("popstate", () => {
      const tabInd = parseInt(window.location.pathname.split("/").pop(), 10);
      if (!isNaN(tabInd)) {
        this.activeTab = tabInd;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.skv-questions {
  max-width: 800px;
  &__result {
    padding: 24px;
    background-color: #eaf7f8;
    color: #1f1f1f;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;

    & span {
      font-family: "Roboto Slab", sans-serif;
      font-size: 23px;
      line-height: 29px;
    }
  }

  &__tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;
  }

  &__tab {
    padding: 24px 16px;
    height: calc(100% - 16px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #1f1f1f;
    border: 1px solid #d2d2d2;
    border-radius: 8px;
    transition: 0.3s;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      padding: 16px 8px;
    }

    &_active {
      background-color: #eaf7f8;
      height: 100%;
      border-color: #eaf7f8;
      border-radius: 8px 8px 0 0;
    }
  }

  &__content {
    background-color: #eaf7f8;
    padding: 0 4px 4px;
    border-radius: 5px;
    &-head {
      padding: 24px 16px;
      background-color: #eaf7f8;
    }

    &-title {
      margin-bottom: 24px;
      color: #00aeca;
      font-family: "Roboto Slab", sans-serif;
      font-size: 23px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-description {
      color: #3c4242;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    &-steps {
      display: flex;
      padding: 24px 16px;
      background-color: #fff;

      @media screen and (max-width: 767px) {
        overflow-x: auto;
      }
    }

    &-step {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      &::after {
        display: block;
        width: 100%;
        height: 1px;
        background-color: #eaf7f8;
        transition: 0.3s;
        margin: auto 0;
        content: "";
        min-width: 30px;
      }
      &-number {
        margin: auto 0;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background-color: #eaf7f8;
        transition: 0.3s;
        color: #b2b4b4;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 13px;
      }

      &_result {
        width: 23px;
        height: 23px;
        align-items: center;
        &::after {
          display: none;
        }
        margin-left: auto;
        flex-direction: column;
        color: #b2b4b4;
        font-family: "Roboto", sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px;
        & .skv-questions__content-step-number {
          margin: 0 auto;
        }
      }

      &_active {
        .skv-questions {
          &__content {
            &-step {
              &-number {
                border: 1px solid #00aeca;
                color: #3c4242;
              }
            }
          }
        }
      }

      &_done {
        &::after {
          background-color: #00aeca;
        }
        .skv-questions {
          &__content {
            &-step {
              &-number {
                background-color: #00aeca;
              }
            }
          }
        }
      }
    }

    &-steps-body {
      background-color: #fff;
      padding: 4px;
      border-radius: 5px;

      &-result-block {
        background-color: #f8f8f8;
        padding: 16px;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-step-body {
      background-color: #f8f8f8;
      padding: 16px;
    }

    &-steps-buttons {
      margin: 24px 0 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @media screen and (max-width: 767px) {
        flex-direction: column;

        & > .button {
          margin: 0 !important;
          margin-bottom: 16px !important;
          width: 100%;
        }
      }
    }

    &-steps-back {
      margin-right: 16px;
      background-color: #fff;
      border: 1px solid #830051;
      color: #830051;

      &:last-child {
        margin-left: 16px;
      }
      @media screen and (min-width: 1221px) {
        &:hover {
          color: #fff;
          background-color: #830051;
        }
      }
    }

    &-steps-next {
      background-color: #d0006f;
      border: 1px solid #d0006f;
      color: #fff;
      @media screen and (min-width: 1221px) {
        &:hover {
          color: #d0006f;
          background-color: #fff;
        }
      }
    }
  }

  &__step {
    &-title {
      color: #1f1f1f;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 16px;
    }

    &-group-title {
      margin-left: 34px;
      margin-bottom: 8px;
      color: #1f1f1f;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }
  }

  &__input-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    &-wrap {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__checkbox {
    &-wrap {
      position: relative;
      width: 18px;
      height: 18px;
      border: 2px solid #f6cce2;
      flex-shrink: 0;
      margin-right: 16px;
      background-color: #fff;
      border-radius: 4px;

      & input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 2;
        border: 0;
        margin: 0;
        padding: 0;
      }

      & svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }

      &_active {
        background-color: #d0006f;
        border-color: #d0006f;

        & svg {
          opacity: 1;
        }
      }
    }

    &-title {
      margin-bottom: 8px;
      color: #1f1f1f;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      cursor: pointer;
    }

    &-description {
      color: #3c4242;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      cursor: pointer;
    }
  }

  &__radio {
    &-wrap {
      border-radius: 50%;
      position: relative;
      width: 18px;
      height: 18px;
      border: 2px solid #f6cce2;
      flex-shrink: 0;
      margin-right: 16px;
      background-color: #fff;

      & input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 2;
        border: 0;
        margin: 0;
        padding: 0;
      }

      & svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
        height: 70%;
        opacity: 0;
      }

      &_active {
        border-color: #d0006f;

        & svg {
          opacity: 1;
        }
      }
    }
  }

  &__bottom {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #b9b9b9;
    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      @media screen and (max-width: 1221px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &-title {
      margin-bottom: 16px;
      color: #00aeca;

      font-family: "Roboto Slab", sans-serif;
      font-size: 23px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px; /* 126.087% */
    }

    &-description {
      color: #3c4242;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  ol {
    list-style: decimal;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3c4242;

    & a {
      color: #830051;
      text-decoration: underline;
    }
  }

  &__download-pdf {
    position: relative;
    overflow: hidden;

    &_progress {
      background-color: transparent;
      color: #d0006f;
    }

    & .button__filler {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      height: 100%;
      background-color: #d0006f;
      color: #fff;
      transition: 1s;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      & > span {
        position: absolute;
        left: 0;
        display: block;
        width: fit-content;
        padding: 0 16px;

        @media screen and (max-width: 767px) {
          width: calc(100vw - 50px);
          text-align: center;
        }
      }
    }

    & > span {
      position: relative;
      z-index: 2;
    }
  }
}

.result-block {
  &__title {
    color: #1f1f1f;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 8px;
  }

  &__item {
    display: grid;
    column-gap: 32px;
    grid-template-columns: 1fr 32px;
    align-items: center;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    &-title {
      color: #3c4242;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }

    &-group {
      grid-column-start: 1;
      grid-column-end: 3;

      & > .result-block__item-title {
        margin-bottom: 8px;
      }

      & .result-block__item {
        padding-left: 24px;
      }
    }
  }
}

.pdf-block {
  margin-bottom: 8px;
  background-color: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  &__content {
    margin: 0 auto;
    padding: 40px 10%;
  }

  &__caption {
    margin-bottom: 24px;
    color: #3c4242;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 116.667% */
    text-align: center;
  }

  &__title {
    color: #1f1f1f;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    margin-bottom: 8px;
  }

  &__result {
    background-color: #fff;
    padding: 40px 0;
    color: #1f1f1f;
    /* Desktop/Body/Medium/S */
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */

    & span {
      font-weight: 400;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr 16px;
    align-items: center;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    &-title {
      color: #3c4242;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      & > span {
        max-width: 70%;
        flex-shrink: 0;
      }

      &::after {
        width: 100%;
        height: 100%;
        display: block;
        content: "";
        border-bottom: 1px dashed #3c4242;
      }
    }

    &-group-title {
      margin-top: 8px;
      &::after {
        display: none;
      }
    }

    &-group {
      grid-column-start: 1;
      grid-column-end: 3;

      & > .pdf-block_item-title {
        margin-bottom: 8px;
      }

      & .pdf-block__item {
        padding-left: 24px;
      }
    }
  }
}
</style>
